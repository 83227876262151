import { parseCookies } from 'nookies';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

let RAILS_ENV = publicRuntimeConfig.RAILS_ENV;

if (publicRuntimeConfig.APP_ENV && ['development', 'stage', 'demo'].includes(publicRuntimeConfig.APP_ENV)) {
  RAILS_ENV = parseCookies()?.domain;
}

const MYMADCRM = RAILS_ENV === 'production';

const AEGLEADS = RAILS_ENV === 'production_aegleads';
const AEGSUPPORT = RAILS_ENV === 'production_aegsupport';
const AEGLEADS_SYSTEM = AEGLEADS || AEGSUPPORT;

const MYASBAGENT = RAILS_ENV === 'production_myasbagent';
const MYSIBAGENT = RAILS_ENV === 'production_mysibagent';
const ASBMIDWEST = RAILS_ENV === 'production_asbmidwest';
const LEGACYCRM = RAILS_ENV === 'production_legacycrm';
const MYCSSAGENT = RAILS_ENV === 'production_mycssagent';
const BROKERAGEASB = RAILS_ENV === 'production_brokerageasb';
const MYARCCRM = RAILS_ENV === 'production_myarccrm';
const CFGSNE = RAILS_ENV === 'production_cfgsne';
const MYASBAGENT_SYSTEM = MYASBAGENT || MYSIBAGENT || ASBMIDWEST || LEGACYCRM || BROKERAGEASB || MYARCCRM;

const ASBAGENTMN = RAILS_ENV === 'production_asbagentmn';
const ASBLEGACY = RAILS_ENV === 'production_asblegacy';
const MYASBFINANCIAL = RAILS_ENV === 'production_myasbfinancial';
const HRGAGENT = RAILS_ENV === 'production_hrgagent';
const MYAIMSAGENT = RAILS_ENV === 'production_myaimsagent';
const CAREINADVANCE = RAILS_ENV === 'production_careinadvance';

const ASB_SYSTEM = MYASBAGENT_SYSTEM || ASBAGENTMN || ASBLEGACY || MYASBFINANCIAL || HRGAGENT || MYAIMSAGENT || CFGSNE;

export const config = (user = null) => {
  return {
    RAILS_ENV: RAILS_ENV,
    MYMADCRM: MYMADCRM,
    AEGLEADS_SYSTEM: AEGLEADS_SYSTEM,
    AEGLEADS: AEGLEADS,
    MYASBAGENT_SYSTEM: MYASBAGENT_SYSTEM,
    MYASBAGENT: MYASBAGENT,
    MYSIBAGENT: MYSIBAGENT,
    ASBMIDWEST: ASBMIDWEST,
    LEGACYCRM: LEGACYCRM,
    MYCSSAGENT: MYCSSAGENT,
    ASBAGENTMN: ASBAGENTMN,
    ASBLEGACY: ASBLEGACY,
    MYASBFINANCIAL: MYASBFINANCIAL,
    HRGAGENT: HRGAGENT,
    MYAIMSAGENT: MYAIMSAGENT,
    CAREINADVANCE: CAREINADVANCE,
    ASB_SYSTEM: ASB_SYSTEM,
    MYARCCRM: MYARCCRM,
    CFGSNE: CFGSNE,
    BROKERAGEASB: BROKERAGEASB,
    POLICY_SUBMISSION_AVAILABLE: (ASB_SYSTEM && !BROKERAGEASB) || MYMADCRM,
    MEDICARE_CENTER: ASB_SYSTEM,
    COMRA_LINK: ASB_SYSTEM && !BROKERAGEASB,
    LEGACY_ID: ASB_SYSTEM,
    GLIC_RX: ASB_SYSTEM && !BROKERAGEASB,
    NPN_NUMBER: ASB_SYSTEM || AEGLEADS,
    WRITING_VIEWING_AGENT_NUMBER: AEGLEADS_SYSTEM || CAREINADVANCE,
    POLICY_TYPE_PRENEED: AEGLEADS_SYSTEM || CAREINADVANCE,
    BUSINESS_UNIT: AEGLEADS_SYSTEM || CAREINADVANCE,
    TRANSFER_LOG: MYMADCRM || AEGLEADS_SYSTEM || CAREINADVANCE || ASB_SYSTEM,
    FIRELIGHT_SUBMISSION: ASB_SYSTEM && !MYARCCRM && !BROKERAGEASB,
    TRANSFER_CONTACT_BY_COUNTY: HRGAGENT,
    CONTACT_ADDITIONAL_STATUSES_SOURCES: ASB_SYSTEM,
    DISABLED_ACTIVITY_TRACKING: MYASBFINANCIAL || BROKERAGEASB,
    REMOVED_COPYRIGHT_LINE: ASB_SYSTEM,
    C1_NUMBER: AEGLEADS_SYSTEM,
    HELP_BUTTON: (ASB_SYSTEM && !MYARCCRM) || CAREINADVANCE,
    REMOVED_COMPANY_NAME_WEBPAGE: ASB_SYSTEM || AEGLEADS,
    COMPANIES_DESCRIPTION_NOT_REQUIRED: ASB_SYSTEM,
    CALL_GUIDE: user?.c_1,
    MINI_CALENDAR: ASB_SYSTEM,
    DASHBOARD_MINI_CALENDAR: ASBMIDWEST,
    GOOGLE_MAP: ASB_SYSTEM,
    FOLLOW_UPS: ASB_SYSTEM || MYCSSAGENT || MYMADCRM,
    REFERRED_BY:
      MYASBAGENT ||
      MYSIBAGENT ||
      ASBMIDWEST ||
      LEGACYCRM ||
      MYCSSAGENT ||
      AEGLEADS ||
      MYAIMSAGENT ||
      HRGAGENT ||
      ASBAGENTMN ||
      CFGSNE ||
      MYASBFINANCIAL ||
      MYMADCRM,
    COMPANY_FIELD:
      MYASBAGENT ||
      MYSIBAGENT ||
      ASBMIDWEST ||
      LEGACYCRM ||
      MYCSSAGENT ||
      ASBAGENTMN ||
      MYAIMSAGENT ||
      CFGSNE ||
      MYMADCRM ||
      MYASBFINANCIAL ||
      HRGAGENT,
    PLAN: AEGLEADS,
    AFFINITY: ASB_SYSTEM,
    CREDIT_UNION: MYASBAGENT,
    DASHBOARD_AFFINITY_LEADS: MYASBAGENT || MYSIBAGENT || ASBLEGACY || CFGSNE || ASBAGENTMN || HRGAGENT || ASBMIDWEST,
    MULTI_ACCOUNT_ACCESS: ASB_SYSTEM || AEGLEADS,
    RM: CAREINADVANCE,
    REPORTS: !BROKERAGEASB,
    EMAIL_LOG: !BROKERAGEASB,
    PRODUCT_INFO: !AEGLEADS && !CAREINADVANCE,
    DISABLED_POOLS: BROKERAGEASB,
    TWO_FACTOR_ENABLED: true,
    CONTACT_PERMISSIONS: true,
    SPOUSE: MYASBAGENT_SYSTEM || MYASBFINANCIAL || CFGSNE || ASBLEGACY || ASBAGENTMN || HRGAGENT || MYCSSAGENT,
    GENDER_IN_POLICY_SUBMISSION: ASB_SYSTEM,
    SSO_FEATURE:
      (AEGLEADS ||
        ASBAGENTMN ||
        MYASBAGENT ||
        MYARCCRM ||
        MYAIMSAGENT ||
        LEGACYCRM ||
        HRGAGENT ||
        ASBLEGACY ||
        CFGSNE ||
        ASBMIDWEST ||
        MYASBFINANCIAL ||
        MYSIBAGENT ||
        ['demo'].includes(publicRuntimeConfig.APP_ENV)) &&
      !['stage', 'development'].includes(publicRuntimeConfig.APP_ENV),
    ACTIVITY_INFO: ASB_SYSTEM && !BROKERAGEASB,
    MARKETING_PROFILE: true,
    RX_DETAILS: ASBLEGACY,
    LINKED_CONTACTS:
      ASBLEGACY ||
      (MYMADCRM && ['C19', 'C20'].includes(user?.company?.number)) ||
      AEGLEADS ||
      MYASBAGENT ||
      MYSIBAGENT ||
      ASBMIDWEST ||
      MYCSSAGENT ||
      CFGSNE ||
      MYAIMSAGENT ||
      MYARCCRM ||
      LEGACYCRM ||
      MYASBFINANCIAL,
    DATA_CAPTURE_DEFAULT_FIELDS: HRGAGENT || (LEGACYCRM && ['C1', 'C18', 'C22'].includes(user?.company?.number)),
    DATA_CAPTURE_DEFAULT_FIELDS_ON_LIST: LEGACYCRM && ['C18', 'C22'].includes(user?.company?.number),
    CONTACTS_DROPZONE: ASBMIDWEST,
    EMAIL_CAMPAIGNS: ASB_SYSTEM && !BROKERAGEASB,
    SMS_CAMPAIGNS: ASB_SYSTEM && !BROKERAGEASB,
    CAMPAIGNS_TRIGGERS_ADMIN_TAB:
      (['development', 'demo'].includes(publicRuntimeConfig.APP_ENV) && ASB_SYSTEM && (user?.admin || user?.manager)) ||
      (AEGLEADS &&
        (user?.admin ||
          ((user?.bu_admin || user?.roles?.includes('compliance_officer')) && (user?.aris || user?.americare)))) ||
      (MYASBAGENT && (user?.admin || user?.manager)) ||
      (MYASBFINANCIAL && (user?.admin || user?.manager)) ||
      (ASBAGENTMN && (user?.admin || user?.manager)) ||
      (ASBMIDWEST && (user?.admin || user?.manager)) ||
      (MYARCCRM && (user?.admin || user?.manager)) ||
      (HRGAGENT && (user?.admin || user?.manager)) ||
      (MYAIMSAGENT && (user?.admin || user?.manager)) ||
      (MYSIBAGENT && (user?.admin || user?.manager)) ||
      (CFGSNE && (user?.admin || user?.manager)),
    SMS_FEATURE:
      (MYMADCRM && ['C19', 'C20', 'C21'].includes(user?.company?.number)) ||
      MYASBAGENT ||
      MYASBFINANCIAL ||
      ASBAGENTMN ||
      ASBMIDWEST ||
      MYARCCRM ||
      HRGAGENT ||
      MYAIMSAGENT ||
      MYSIBAGENT ||
      CFGSNE,
    SMS_DISABLED:
      [].includes(user?.company?.number) ||
      [].includes(user?.business_unit?.number) ||
      [
        '9914',
        '2364',
        '9588',
        '6112',
        '8236',
        '6200',
        '6218',
        '1656',
        '8215',
        '8173',
        '8520',
        '7448',
        '5974',
        '8370',
        '7516'
      ].includes(user?.number),
    DIALER_DISABLED:
      [].includes(user?.company?.number) || ['BU5'].includes(user?.business_unit?.number) || [].includes(user?.number),
    CSG_FEATURE: ASB_SYSTEM && !BROKERAGEASB,
    MEDICARE_IMPORT_LEAD: ASB_SYSTEM,
    GOOGLE_MAPS_API_KEY: 'AIzaSyBYRy5Ry1Ow_BdCh9PY29nP7Vd2brjp8mk',
    GOOGLE_MAPS_LIBRARIES: ['places'],
    MAX_TRANSFER_LIMIT: 100_000,
    MAX_EXPORT_LIMIT: 999_999,
    MAX_PRINT_LIMIT: 500,
    CONTACTS_MERGE: (ASBLEGACY && user?.admin) || ['demo'].includes(publicRuntimeConfig.APP_ENV),
    TRAINING_APP:
      ['development', 'demo'].includes(publicRuntimeConfig.APP_ENV) || (ASB_SYSTEM && !BROKERAGEASB) || MYCSSAGENT,
    API_TRACKING: ['development', 'demo'].includes(publicRuntimeConfig.APP_ENV),
    TRAINING_REQUIRED: LEGACYCRM,
    QUICK_FILTERS: ['development', 'demo'].includes(publicRuntimeConfig.APP_ENV),
    DEMO_FEATURE: ['development', 'demo'].includes(publicRuntimeConfig.APP_ENV),
    SYSTEM_VERSION: {
      production_aegleads: '0.2.34',
      default: '0.2.33'
    }
  };
};
