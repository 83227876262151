import request from '@/utils/request';
import { parseCookies } from 'nookies';

export const CURRENT_ACCOUNT_INFO = 'CURRENT_ACCOUNT_INFO';
export const PLANS = 'PLANS';
export const PAYMENT_INFOS = 'PAYMENT_INFOS';
export const SAVE_CHOSEN_PLAN = 'SAVE_CHOSEN_PLAN';

export const fetchCurrentAccount =
  ({ ctx = null } = {}) =>
  async dispatch => {
    const response = await request({
      path: `/v1/accounts/info`,
      token: ctx?.req && parseCookies(ctx)['token'],
      current_admin_token: ctx?.req && parseCookies(ctx)['current_admin_token'],
      impersonator_token: ctx?.req && parseCookies(ctx)['impersonator_token']
    });
    dispatch({
      payload: response,
      type: CURRENT_ACCOUNT_INFO
    });
  };

export const connectCard = body => async () =>
  await request({
    path: `/v1/accounts/connect_card`,
    method: 'POST',
    body
  });

export const fetchPlans =
  (query = {}) =>
  async dispatch => {
    const response = await request({
      path: `/v1/plans`,
      query
    });
    dispatch({
      payload: response,
      type: PLANS
    });
  };

export const updatePlan = body => async () =>
  await request({
    path: `/v1/accounts/update_plan`,
    method: 'POST',
    body
  });

export const fetchPaymentInfos =
  ({ limit, page }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/accounts/history`,
      query: { limit, page: page }
    });
    dispatch({
      payload: response,
      type: PAYMENT_INFOS
    });
  };

export const cancelSubscription =
  (body = {}) =>
  async () =>
    await request({
      path: `/v1/accounts/cancel_subscription`,
      method: 'PUT',
      body
    });

export const saveChosenPlan = plan => async dispatch =>
  dispatch({
    payload: plan,
    type: SAVE_CHOSEN_PLAN
  });
