import React, { Component } from 'react';
import { withErrorBoundary } from '@/utils/errors';
import classNames from 'classnames';
import styles from './DialerHistory.module.scss';
import moment from 'moment';

class DialerHistory extends Component {
  state = {};

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <div className={classNames(styles.DialerHistoryWrapper)}>
        <div className={classNames(styles.DialerHistoryTitle)}>
          <h3>Recent</h3>
        </div>

        <div className={classNames(styles.DialerHistoryItems)}>
          {this.props.callHistory.map((call, key) => {
            let dir = call.direction == 'inbound';
            let number = call.call_direction == 'inbound' ? call.call_from : call.call_to;
            let location = call.call_direction == 'inbound' ? call.caller_city + ' ' + call.caller_state : '';

            return (
              <div
                key={key}
                className={classNames(styles.DialerHistoryItem)}
                onClick={() => this.props.onRedial(number)}
              >
                <div className={classNames(styles.DialerHistoryItemNumber)}>
                  <p className={classNames(styles.DialerHistoryItemNumberText)}>{number}</p>
                  <p className={classNames(styles.DialerHistoryItemLocationText)}>{location}</p>
                </div>
                <div className={classNames(styles.DialerHistoryItemDate)}>
                  <p className={classNames(styles.DialerHistoryItemDateText)}>{moment(call.created_at).calendar()}</p>
                </div>
                {/* <div className={classNames(styles.DialerHistoryItemType)}>
                  <p className={classNames(styles.DialerHistoryItemTypeIco)}>
                    {call.call_direction == 'inbound' && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-phone-incoming"
                      >
                        <polyline points="16 2 16 8 22 8"></polyline>
                        <line x1="23" y1="1" x2="16" y2="8"></line>
                        <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                      </svg>
                    )}
                    {call.call_direction == 'outbound' && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-phone-outgoing"
                      >
                        <polyline points="23 7 23 1 17 1"></polyline>
                        <line x1="16" y1="8" x2="23" y2="1"></line>
                        <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                      </svg>
                    )}
                    {call.call_direction == 'missed' && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-phone-missed"
                      >
                        <line x1="23" y1="1" x2="17" y2="7"></line>
                        <line x1="17" y1="1" x2="23" y2="7"></line>
                        <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                      </svg>
                    )}
                  </p>
                </div> */}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withErrorBoundary(DialerHistory);
