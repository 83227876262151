import {
  CONVERSATIONS,
  MESSAGES,
  SEND_MESSAGE,
  SMS_PLANS,
  SMS_PLAN,
  SMS_SUBSCIPTIONS,
  SMS_SUBSCIPTION,
  SMS_SUBSCIPTION_COMPANY,
  SMS_USAGE,
  SMS_LICENSES,
  SMS_LICENSE,
  SMS_ORDERS,
  SMS_ORDER,
  SMS_ADDONS,
  PHONE_VERIFICATIONS
} from '@/store/actions/sms.actions';

const initialState = {
  conversations: [],
  messages: [],
  send_message: {},
  plans: [],
  plans_meta: [],
  plan: {},
  addons: [],
  addons_meta: [],
  addon: {},
  subscriptions: [],
  subscriptions_meta: [],
  subscription: {},
  usage: [],
  orders: [],
  orders_meta: [],
  order: {},
  licenses: [],
  licenses_meta: [],
  license: {},
  phone_verifications: [],
  phone_verifications_meta: {}
};

export default function dialer(state = initialState, action) {
  switch (action.type) {
    case CONVERSATIONS:
      return {
        ...state,
        conversations: action.payload.data
      };
    case MESSAGES:
      return {
        ...state,
        messages: action.payload.data
      };
    case SEND_MESSAGE:
      return {
        ...state,
        send_message: action.payload.data
      };
    case SMS_PLANS:
      return {
        ...state,
        plans: action.payload.data,
        plans_meta: action.payload.meta
      };
    case SMS_PLAN:
      return {
        ...state,
        plan: action.payload.data
      };
    case SMS_SUBSCIPTIONS:
      return {
        ...state,
        subscriptions: action.payload.data,
        subscriptions_meta: action.payload.meta
      };
    case SMS_SUBSCIPTION:
      return {
        ...state,
        subscription: action.payload.data
      };
    case SMS_SUBSCIPTION_COMPANY:
      return {
        ...state,
        company_subscription: action.payload.data
      };
    case SMS_USAGE:
      return {
        ...state,
        usage: action.payload.data
      };
    case SMS_LICENSES:
      return {
        ...state,
        licenses: action.payload.data
        // licenses_meta: action.payload.meta
      };
    case SMS_LICENSE:
      return {
        ...state,
        license: action.payload.data
      };
    case SMS_ORDERS:
      return {
        ...state,
        orders: action.payload.data
        // orders_meta: action.payload.meta
      };
    case SMS_ORDER:
      return {
        ...state,
        order: action.payload.data
      };
    case SMS_ADDONS:
      return {
        ...state,
        addons: action.payload.data,
        addons_meta: action.payload.meta
      };
    case PHONE_VERIFICATIONS:
      return {
        ...state,
        phone_verifications: action.payload.data,
        phone_verifications_meta: action.payload.meta
      };
    default:
      return state;
  }
}

export const selectPhoneVerifications = state => state?.sms?.phone_verifications;
export const selectPhoneVerificationsMeta = state => state?.sms?.phone_verifications_meta;
export const selectPhoneVerificationsCount = state => state?.sms?.phone_verifications_meta.total_count ?? 0;
