import { filesize } from 'filesize';
import HtmlReactParser from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';
import moment from 'moment';
import { config } from '@/config';
import Router from 'next/router';

export const parseDateUTC = str => {
  if (!str) return;
  const date = new Date(str);

  if (date instanceof Date && isNaN(date)) return undefined;

  // for change date picker value
  if (date.getHours() === 0) return date;

  // for value from BE with timezone
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export const fileSizeLabel = fileSize => {
  const { value, symbol } = filesize(fileSize, { output: 'object' });

  return Number.isInteger(value) ? value + '.0 ' + symbol : filesize(fileSize);
};

export const signedFloatRegex = /^[+-]?((\d+\.?\d*)|(\.\d*))$/;

export const formatOnlyDigits = number => {
  return number?.replace(/[^0-9]/g, '');
};

export const formatNumber = number => {
  if ((number !== 0 && !number) || isNaN(number)) return '';
  return number.toLocaleString('en');
};

export const fileName = name => {
  if (!name) return;
  return name.replace(/\s/g, '').replace(/'/g, '').replace(/[()]/g, '').split('.')[0];
};

export const zipFormat = zip_code => {
  const code = zip_code && zip_code.toString();
  return code && code.substring(0, 5) + ' ' + code.substring(5, code.length);
};

export const groupBy = (array, key) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

export const HtmlParser = html => {
  return HtmlReactParser(DOMPurify.sanitize(html, { ADD_ATTR: ['target'] }));
};

// set '' instead of null or undefined values in object
export const emptyStringInsteadNull = obj => {
  const result = obj;
  if (typeof result === 'object') {
    for (let item in result) {
      if (typeof result[item] === 'object') emptyStringInsteadNull(result[item]);
      result[item] = result[item] || '';
    }
  }
  return result;
};

export const toPhoneFormat = phoneNumberString => {
  const PHONE_FORMAT_US_LENGTH = 11;
  let cleaned = (phoneNumberString || '')?.toString().replace(/\D/g, '');
  let match =
    cleaned.length === PHONE_FORMAT_US_LENGTH
      ? cleaned?.match(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/)
      : cleaned?.match(/^(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,4})/);

  if (match) {
    return (match[1] + '-' + match[2] + '-' + match[3] + '-' + match[4]).replace(/-+$/g, '');
  }

  return cleaned;
};

export const toZipCodeFormat = (zipCodeString, additionalParams = {}) => {
  const regex = additionalParams.numeric ? /\d/g : additionalParams.alphabetic ? /[a-zA-Z]/g : /\d|[a-zA-Z]/g;
  let cleaned = (zipCodeString || '')?.toString().match(regex)?.join('');
  let match = cleaned?.match(/(\w{0,5})(\w{0,4})/);
  if (match) {
    return (match[1] + '-' + match[2]).replace(/-+$/g, '');
  }
  return cleaned || '';
};

export const validateDay = inputObj => {
  let value = inputObj.value;
  if (value.length >= 2) {
    let month = value.slice(0, 2);
    let day = value.slice(2);
    let limit = moment(`${month}/01/2012`).endOf('month').format('DD');
    if (Number(day[0]) > Number(limit[0]) || Number(day) > Number(limit)) return false;
  }
  return !value || value.match(/^([01])$|(0[1-9]|1[012])$|(0[1-9]|1[012])([0-3]|3[01]|[12][0-9]|0[1-9])$/g);
};

export const allowNumbersAndLetters = event => {
  if (config().MYASBFINANCIAL) return !/^[a-zA-Z\d\s#/]+$/g.test(event.key) && event.preventDefault();
  return !/^[a-zA-Z\d\s]+$/g.test(event.key) && event.preventDefault();
};

export const filterForSearch = symbol => {
  return /[<(^>!#:";№%*~{}?|+)[\]\\]/g.test(symbol.key) && symbol.preventDefault();
};

export const linkWithHttps = value =>
  value?.match('https://')
    ? value?.replace(/\s/g, '').length > 'https://'.length
      ? value
      : ''
    : value?.replace(/\s/g, '')
    ? 'https://' + value
    : '';

export const toPriceCurrency = (value, minimumFractionDigits = 2) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits
  });
  return formatter.format((value || 0).toString().replace(/,/g, '')).replace(/\$/g, '');
};

export const weekOfMonth = date => Math.ceil(moment(date).date() / 7);

export function debounce(func, delay = 500) {
  let lastCall;
  let lastCallTimer;
  return (...args) =>
    new Promise(resolve => {
      let previousCall = lastCall;

      lastCall = Date.now();

      if (previousCall && lastCall - previousCall <= delay) {
        clearTimeout(lastCallTimer);
      }

      lastCallTimer = setTimeout(() => resolve(func(...args)), delay);
    });
}

const crypto = typeof window === 'undefined' ? null : window.crypto || window.msCrypto;

export const random = ({ limit = 8 } = {}) => {
  if (typeof limit !== 'number' || limit < 2) limit = 8;
  if (limit > 16) limit = 8;
  if (crypto) {
    return Number(crypto.getRandomValues(new Uint32Array(1))[0].toString().slice(0, limit));
  } else {
    let min = Number(1 + '0'.repeat(limit - 1));
    let max = Number('9'.repeat(limit));
    return Math.floor(Math.random() * (max - min)) + min;
  }
};

export const isTouchScreen = () =>
  typeof window !== 'undefined' && ('ontouchstart' in window || navigator.msMaxTouchPoints);

export const phoneWithUSCountryCode = phone => {
  const clear = phone.replace(/\D/g, '');
  return clear.length === 10 ? '1' + clear : clear;
};

// Gets the display name of a JSX component for dev tools
export const getDisplayName = Component => Component.displayName || Component.name || 'Component';

/* USE ONLY IN CLASS COMPONENTS
 * wrapper to make this.setState work properly with async/await
 * state - state you want to set
 * usage: create method in class and bind this function to `this`
 * example:
 *
 * import { setStateAsync } from '@/utils/helpers';
 *
 * class Index extends Component {
 *
 *   setStateAsync = setStateAsync.bind(this);
 *
 * }
 */
export function setStateAsync(state) {
  return new Promise(resolve => {
    this.setState(state, resolve);
  });
}

export const changeRedirect = (condition, path) => {
  if (condition) return Router.push(path);
};

export const selectValue = (array, value) => {
  return array?.find(el => el.value === value) || null;
};

export const isAbortError = err => {
  return (typeof err === 'string' && err.match('AbortError')) || (err instanceof Error && err.name === 'AbortError');
};

export const wait = milliseconds => {
  return new Promise(resolve => {
    let timeoutId = setTimeout(() => resolve(timeoutId), milliseconds);
  });
};

export const toProperCase = inputString => {
  const words = inputString.split('_');
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalizedWords.join(' ');
};

export const humanizeString = str => {
  if (!str) return '';
  // Replace underscores or dashes with spaces
  let humanized = String(str).replace(/[_-]/g, ' ');
  // Add spaces before capital letters (camel case)
  humanized = humanized.replace(/([a-z])([A-Z])/g, '$1 $2');
  // Capitalize the first letter
  humanized = humanized.charAt(0).toUpperCase() + humanized.slice(1);
  return humanized;
};
