import React, { Component } from 'react';
import { withErrorBoundary } from '@/utils/errors';
import classNames from 'classnames';
import styles from './DialerNav.module.scss';

class DialerNav extends Component {
  state = {};

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <div className={classNames(styles.DialerNavWrapper)}>
        {!this.props.isActiveCall && (
          <div className={classNames(styles.DialerNavButtons)}>
            <div
              className={classNames(styles.DialerNavButton, this.props.route !== 'history' ? styles.Active : '')}
              onClick={() => this.props.onNavigate('keypad')}
            >
              Keypad
            </div>
            <div
              className={classNames(styles.DialerNavButton, this.props.route === 'history' ? styles.Active : '')}
              onClick={() => this.props.onNavigate('history')}
            >
              Recent
            </div>
          </div>
        )}
        {this.props.isActiveCall && (
          <div className={classNames(styles.DialerNavButtons)}>
            <div
              className={classNames(styles.DialerNavButton, this.props.route !== 'dialpad' ? styles.Active : '')}
              onClick={() => this.props.onNavigate('active')}
            >
              Call
            </div>
            <div
              className={classNames(styles.DialerNavButton, this.props.route === 'dialpad' ? styles.Active : '')}
              onClick={() => this.props.onNavigate('dialpad')}
            >
              Keypad
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withErrorBoundary(DialerNav);
