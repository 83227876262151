globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"CcK0h6EFOSAOfNwi_89C_"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs';
// import { Integrations } from '@sentry/tracing';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: publicRuntimeConfig.SENTRY_DSN,
    hideSourceMaps: true,
    environment: publicRuntimeConfig.RAILS_ENV,
    beforeSend(event) {
      if (
        event.exception &&
        (event.exception.values[0].value.includes('Request failed') ||
          event.exception.values[0].value.includes('AbortError'))
      ) {
        return null; // This will skip sending the event to Sentry.
      }
      return event; // Send the event to Sentry by default.
    },
    debug: false,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1
    // integrations: [new Integrations.BrowserTracing({ tracesSampleRate: 1 })]
  });
}
