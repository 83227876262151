import request from '@/utils/request';

export const DEVICE_ENABLED = 'DEVICE_ENABLED';
export const DEVICE_TOKEN = 'DEVICE_TOKEN';
export const DEVICE_STATUS = 'DEVICE_STATUS';
export const CALL_STATUS = 'CALL_STATUS';
export const CALL_LIST = 'CALL_LIST';
export const CALL_HISTORY = 'CALL_HISTORY';
export const DEVICE_VISIBLE = 'DEVICE_VISIBLE';
export const ASSOCIATED_CONTACT = 'ASSOCIATED_CONTACT';
export const ASSOCIATED_CONTACTS = 'ASSOCIATED_CONTACTS';

export const DIALER_PLANS = 'DIALER_PLANS';
export const DIALER_PLAN = 'DIALER_PLAN';

export const DIALER_SUBSCIPTIONS = 'DIALER_SUBSCIPTIONS';
export const DIALER_SUBSCIPTION = 'DIALER_SUBSCIPTION';
export const DIALER_SUBSCIPTION_COMPANY = 'DIALER_SUBSCIPTION_COMPANY';

export const DIALER_USAGE = 'DIALER_USAGE';

export const DIALER_LICENSES = 'DIALER_LICENSES';
export const DIALER_LICENSE = 'DIALER_LICENSE';

export const DIALER_ORDERS = 'DIALER_ORDERS';
export const DIALER_ORDER = 'DIALER_ORDER';

export const DIALER_ADDONS = 'DIALER_ADDONS';

// ENDPOINTS
// fetch_device_token
// fetch_call_list
// fetch_associated_contact
// device_is_enabled
// device_start_disclaimer_prompt
// device_start_recording_prompt
// device_stop_call_recording
// device_resume_call_recording

export const enableCallCapability = user => async dispatch => {
  dispatch({
    payload: user.profile.dialer_enabled,
    type: DEVICE_ENABLED
  });
};

export const fetchDeviceToken = () => async dispatch => {
  const response = await request({
    path: `/v1/dialer/fetch_device_token`
  });
  dispatch({
    payload: response.token,
    type: DEVICE_TOKEN
  });
};

export const toggleDeviceVisibility = visibility => async dispatch => {
  dispatch({
    payload: !visibility,
    type: DEVICE_VISIBLE
  });
};

export const updateDeviceStatus = status => async dispatch => {
  dispatch({
    payload: status,
    type: DEVICE_STATUS
  });
};

export const updateCallStatus = status => async dispatch => {
  dispatch({
    payload: status,
    type: CALL_STATUS
  });
};

export const fetchActiveCalls = body => async dispatch => {
  const response = await request({
    path: `/v1/dialer/fetch_active_calls`,
    method: 'POST',
    body: {}
  });
  dispatch({
    payload: response.call_list,
    type: CALL_LIST
  });
};

export const fetchHistoricalCalls = body => async dispatch => {
  const response = await request({
    path: `/v1/dialer/fetch_historical_calls`,
    method: 'POST',
    body: {}
  });
  dispatch({
    payload: response.call_history,
    type: CALL_HISTORY
  });
};

export const fetchCallLogs = body => async dispatch => {
  const response = await request({
    path: `/v1/dialer/fetch_call_logs`,
    method: 'POST',
    body: body
  });
  return response;
};

export const fetchAssociatedContact = body => async dispatch => {
  const response = await request({
    path: `/v1/dialer/fetch_associated_contact`,
    method: 'POST',
    body: body
  });
  dispatch({
    payload: response.contact,
    type: ASSOCIATED_CONTACT
  });
};

export const fetchAssociatedContacts = body => async dispatch => {
  const response = await request({
    path: `/v1/dialer/fetch_associated_contacts`,
    method: 'POST',
    body: body
  });
  return response;
};

export const viewAvailableNumbers = area_code => async dispatch => {
  const response = await request({
    path: `/v1/dialer/view_available_numbers`,
    method: 'POST',
    body: { area_code: area_code }
  });
  return response;
};

export const deviceStartDisclaimerPrompt = body => async () =>
  await request({
    path: `/v1/dialer/device_start_disclaimer_prompt`,
    method: 'POST',
    body: body
  });

export const deviceResumeCallRecording = body => async () =>
  await request({
    path: `/v1/dialer/device_resume_call_recording`,
    method: 'POST',
    body: body
  });

export const deviceStopCallRecording = body => async () =>
  await request({
    path: `/v1/dialer/device_stop_call_recording`,
    method: 'POST',
    body: body
  });

/**
 *
 * !! --------------------------> Plans
 *
 */

export const fetchDialerPlans = body => async dispatch => {
  const response = await request({
    path: `/v1/dialer/dialer_plans`
  });
  dispatch({ payload: response, type: DIALER_PLANS });
};

export const fetchDialerAddons = body => async dispatch => {
  const response = await request({
    path: `/v1/dialer/dialer_plans_addons`
  });
  dispatch({ payload: response, type: DIALER_ADDONS });
};

// Fetch plans for enterprise
export const fetchDialerPlansEnterprise = body => async dispatch => {
  const response = await request({
    path: `/v1/dialer/dialer_plans_enterprise`
  });
  dispatch({ payload: response, type: DIALER_PLANS });
};

// Fetch plans for administartion
export const fetchDialerPlansAdmin = ({ q, order, page, limit, all }) => {
  async dispatch => {
    const response = await request({
      path: `/v1/dialer/dialer_plans_admin`,
      query: { q, order, page, limit, all }
    });
    dispatch({ payload: response, type: DIALER_PLANS });
  };
};

// Fetch plan
export const fetchDialerPlan = ({ id }) => {
  async dispatch => {
    const response = await request({
      path: `/v1/dialer/dialer_plans/${id}`
    });
    dispatch({
      payload: response,
      type: DIALER_PLAN
    });
  };
};

// Create plan
export const createDialerPlan = body => async () => {
  await request({
    path: `/v1/dialer/dialer_plans`,
    method: 'POST',
    body
  });
};

// Update plan
export const updateDialerPlan = (id, body) => async () => {
  await request({
    path: `/v1/dialer/dialer_plans/${id}`,
    method: 'PATCH',
    body
  });
};

// Delete plan
export const deleteDialerPlan = id => async () => {
  await request({
    path: `/v1/dialer/dialer_plans/${id}`,
    method: 'DELETE'
  });
};

/**
 *
 * !! --------------------------> Subscriptions
 *
 */

export const fetchDialerSubscriptions =
  ({ q, order, order_field, page, limit, active, mine }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/dialer/dialer_subscriptions`,
      method: 'GET',
      query: { q, order, order_field, page, limit, active, mine }
    });
    dispatch({
      payload: response,
      type: DIALER_SUBSCIPTIONS
    });
  };

export const fetchDialerSubscription =
  ({ id }) =>
  async dispatch => {
    const response = await request({
      path: `/v1/dialer/dialer_subscriptions/${id}`
    });
    dispatch({
      payload: response,
      type: DIALER_SUBSCIPTION
    });
  };

export const createDialerSubscription = body => async () => {
  let response = await request({
    path: `/v1/dialer/dialer_subscriptions`,
    method: 'POST',
    body
  });
  return response;
};

export const updateDialerSubscription = (id, body) => async () => {
  let response = await request({
    path: `/v1/dialer/dialer_subscriptions/${id}`,
    method: 'PATCH',
    body
  });

  return response;
};

export const cancelDialerSubscription = (id, type) => async () =>
  await request({
    path: `/v1/dialer/dialer_subscriptions/${id}/cancel`,
    method: 'POST',
    body: { type }
  });

export const fetchDialerActiveSubscription = type => async dispatch => {
  const response = await request({
    path: `/v1/dialer/dialer_subscriptions_active`,
    query: { type }
  });
  dispatch({
    payload: response && response.data ? response : { data: {} },
    type: type && type === 'company' ? DIALER_SUBSCIPTION_COMPANY : DIALER_SUBSCIPTION
  });
};

export const fetchUsage = body => async dispatch => {
  const response = await request({
    path: `/v1/dialer/dialer_subscriptions_usage`
  });
  dispatch({
    payload: response,
    type: DIALER_USAGE
  });
};

export const fetchDialerLicenses = body => async dispatch => {
  const response = await request({
    path: `/v1/dialer/dialer_licenses`
  });
  dispatch({
    payload: response,
    type: DIALER_LICENSES
  });
};

export const fetchDialerLicense = (id, body) => async dispatch => {
  const response = await request({
    path: `/v1/dialer/dialer_licenses/${id}`
  });
  dispatch({
    payload: response,
    type: DIALER_LICENSE
  });
};

export const updateDialerLicense = (id, body) => async () =>
  await request({
    path: `/v1/dialer/dialer_licenses/${id}`,
    method: 'PATCH',
    body
  });

export const orderDialerLicenses = body => async () =>
  await request({
    path: `/v1/dialer/dialer_licenses_order`,
    method: 'POST',
    body
  });

/**
 *
 * !! --------------------------> ORDERS
 *
 */

export const fetchDialerOrders = () => async dispatch => {
  const response = await request({
    path: `/v1/dialer/dialer_orders`
  });
  dispatch({
    payload: response,
    type: DIALER_ORDERS
  });
};

export const fetchDialerOrder = id => async dispatch => {
  const response = await request({
    path: `/v1/dialer/dialer_orders/${id}`
  });
  dispatch({
    payload: response,
    type: DIALER_ORDER
  });
};
