import isObject from 'isobject';

import {
  CURRENT_USER_INFO,
  PROFILE,
  FAVORITE_USERS,
  DASHBOARD,
  RESET_DASHBOARD,
  CHANGE_LAST_OTP_AT,
  SIGNUP,
  SEARCH_PHONE_NUMBERS,
  API_KEYS,
  UPDATE_API_KEY,
  DELETE_API_KEY,
  QUICK_FILTERS
} from '@/store/actions/currentUser.actions';
import { FONT_SIZE_DEFAULT, PRIMARY_COLOR_GREEN, BACKGROUND_DEFAULT } from '@/store/actions/theme.actions';

const initialState = {
  id: null,
  tos: null,
  updated_tos: null,
  username: null,
  roles: [],
  email: null,
  profile: {
    first_name: null,
    last_name: null,
    personal_photo: {},
    logo: {},
    font_size: FONT_SIZE_DEFAULT,
    primary_color: PRIMARY_COLOR_GREEN,
    background: BACKGROUND_DEFAULT
  },
  signup: {},
  favorite_users: [],
  dashboard_info: {},
  available_phone_numbers: [],
  api_keys: [],
  quick_filters: []
};

export default function currentUser(state = initialState, action) {
  switch (action.type) {
    case CURRENT_USER_INFO:
      return {
        ...state,
        ...action.payload.data,
        ...accountType(action.payload.data.profile?.account_type),
        profile: {
          ...action.payload.data.profile,
          font_size: action.payload.data.profile.font_size || FONT_SIZE_DEFAULT,
          primary_color: action.payload.data.profile.primary_color || PRIMARY_COLOR_GREEN,
          background: action.payload.data.profile.background || BACKGROUND_DEFAULT
        }
      };
    case CHANGE_LAST_OTP_AT:
      return {
        ...state,
        last_otp_at: action.payload
      };
    case PROFILE:
      return {
        ...state,
        profile: action.payload.data
      };
    case FAVORITE_USERS:
      return {
        ...state,
        favorite_users: action.payload.data
      };
    case RESET_DASHBOARD:
      return {
        ...state,
        dashboard_info: {}
      };
    case DASHBOARD: {
      let dashboard_info = {};
      Object.entries({ ...action.payload, ...state.dashboard_info }).forEach(([key, value]) => {
        if (isObject(value)) {
          dashboard_info[key] = {
            ...value,
            ...action.payload[key]
          };
        } else {
          if (['client_birthdays', 'prospect_birthdays'].includes(key)) {
            dashboard_info[key] = [...(state.dashboard_info[key] || []), ...(action.payload[key] || [])];
          } else {
            dashboard_info[key] = value;
          }
        }
      });

      return {
        ...state,
        dashboard_info
      };
    }
    case SIGNUP:
      return {
        ...state,
        signup: action.payload.data
      };
    case SEARCH_PHONE_NUMBERS:
      return {
        ...state,
        available_phone_numbers: action.payload
      };
    case API_KEYS:
      return {
        ...state,
        api_keys: action.payload.data
      };
    case UPDATE_API_KEY:
      return {
        ...state,
        api_keys: state.api_keys.map(el => (el.id === action.payload.data.id ? action.payload.data : el))
      };
    case DELETE_API_KEY:
      return {
        ...state,
        api_keys: state.api_keys.filter(el => el.id !== action.payload.data.id)
      };
    case QUICK_FILTERS:
      return {
        ...state,
        quick_filters: action.payload.data
      };
    default:
      return state;
  }
}

const accountType = type => {
  switch (type) {
    case 'sub_admin':
      return { sub_admin: true, admin: true };
    default:
      if (type) {
        return { [type]: true };
      } else {
        return {};
      }
  }
};
